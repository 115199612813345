import { useState, useRef, useEffect, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';
import { formatTimeAgo } from '../utils/formatters';
import Pagination from './Pagination';
import NoImagePlaceholder from './NoImagePlaceholder';
import NotesModal from './NotesModal';

export default function RequestManagement() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [showAll, setShowAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const searchInputRef = useRef(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const queryClient = useQueryClient();
  
  // Debounce search query to avoid too many API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // Wait for 500ms after user stops typing
    
    return () => clearTimeout(timer);
  }, [searchQuery]);
  
  // Reset page when debounced query changes
  useEffect(() => {
    setPage(1);
  }, [debouncedSearchQuery]);
  
  const { data: requestsData, isLoading: requestsLoading } = useQuery({
    queryKey: ['admin-requests', page, pageSize, selectedStatus, debouncedSearchQuery],
    queryFn: async () => {
      const params = new URLSearchParams({
        page,
        limit: pageSize,
        ...(selectedStatus && { status: selectedStatus }),
        ...(debouncedSearchQuery && { search: debouncedSearchQuery })
      });
      
      const response = await axios.get(
        `${config.apiUrl}/api/admin/requests?${params}`,
        { headers: config.getAuthHeaders() }
      );
      return response.data;
    },
    keepPreviousData: true // Keep old data while fetching new data
  });

  const { data: statuses, isLoading: statusesLoading } = useQuery({
    queryKey: ['statuses'],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiUrl}/api/admin/statuses`,
        { headers: config.getAuthHeaders() }
      );
      return response.data;
    },
    staleTime: 5 * 60 * 1000, // Cache statuses for 5 minutes
    cacheTime: 30 * 60 * 1000 // Keep in cache for 30 minutes
  });

  const updateStatusMutation = useMutation({
    mutationFn: async ({ requestId, statusId }) => {
      try {
        const response = await axios.patch(
          `${config.apiUrl}/api/admin/requests/${requestId}/status`,
          { statusId },
          {
            headers: config.getAuthHeaders()
          }
        );
        return response.data;
      } catch (error) {
        console.error('Update status error:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-requests']);
    }
  });

  const updateNotesMutation = useMutation({
    mutationFn: async ({ requestId, notes }) => {
      const response = await axios.patch(
        `${config.apiUrl}/api/admin/requests/${requestId}/notes`,
        { notes },
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-requests']);
    }
  });

  const deleteRequestMutation = useMutation({
    mutationFn: async (requestId) => {
      const response = await axios.delete(
        `${config.apiUrl}/api/admin/requests/${requestId}`,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-requests']);
    }
  });

  const handleDeleteRequest = (requestId) => {
    if (window.confirm('Sigur doriți să ștergeți această cerere?')) {
      deleteRequestMutation.mutate(requestId);
    }
  };

  // Don't show a full-page loading state, just render the component with a loading indicator
  const isLoading = requestsLoading || statusesLoading;
  
  // Keep focus on search input when typing
  useEffect(() => {
    if (searchInputRef.current) {
      // Use a small timeout to ensure focus happens after any other DOM updates
      const focusTimeout = setTimeout(() => {
        searchInputRef.current.focus();
      }, 10);
      return () => clearTimeout(focusTimeout);
    }
  }, [searchQuery, debouncedSearchQuery, isLoading]);

  const requests = requestsData?.results || [];
  const pagination = requestsData?.pagination;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        {isLoading && (
          <div className="fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg text-primary-600 font-medium">
              Se încarcă...
            </div>
          </div>
        )}
        <div className="flex items-center space-x-4">
          <div className="relative">
            <div className="flex space-x-2">
              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    // Don't reset page here, do it when debounced query changes
                  }}
                  placeholder="Caută după titlu, an sau utilizator..."
                  className="w-64 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  ref={searchInputRef}
                />
                {searchQuery && (
                  <button
                    onClick={() => {
                      setSearchQuery('');
                      setDebouncedSearchQuery(''); // Also clear the debounced query
                      setPage(1); // Reset to first page when clearing search
                    }}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                )}
              </div>
              <select
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  setPage(1); // Reset to first page on status change
                }}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
              >
                <option value="">Toate statusurile</option>
                {statuses?.map((status) => (
                  <option 
                    key={status._id} 
                    value={status._id}
                  >
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {pagination && (
          <Pagination
            currentPage={pagination.page}
            totalItems={pagination.total}
            pageSize={pagination.limit}
            onPageChange={setPage}
            onPageSizeChange={(newSize) => {
              setPageSize(newSize);
              setPage(1); // Reset to first page when changing page size
            }}
          />
        )}
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Poster
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Titlu
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Solicitant
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Creat
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actualizat
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Acțiuni
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {requests.map((request, index) => (
              <tr key={request._id} className="even:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {(pagination.page - 1) * pagination.limit + index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {request.posterPath ? (
                    <a
                      href={`https://www.themoviedb.org/${request.type === 'series' ? 'tv' : 'movie'}/${request.tmdbId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-12 h-16 relative overflow-hidden rounded hover:opacity-75 transition-opacity border border-gray-200"
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w92${request.posterPath}`}
                        alt={request.title}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    </a>
                  ) : (
                    <a
                      href={`https://www.themoviedb.org/${request.type === 'series' ? 'tv' : 'movie'}/${request.tmdbId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-12 h-16 relative overflow-hidden rounded hover:opacity-75 transition-opacity border border-gray-200"
                    >
                      <div className="w-full h-full flex items-center justify-center bg-gray-100">
                        <NoImagePlaceholder className="w-6 h-6" />
                      </div>
                    </a>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm font-medium text-gray-900">
                      {request.title}
                      {request.year && ` (${request.year})`}
                    </span>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      request.type === 'movie' 
                        ? 'bg-rose-50 text-rose-700'
                        : 'bg-blue-50 text-blue-700'
                    }`}>
                      {request.type === 'movie' ? 'Film' : 'Serial'}
                      {request.type === 'series' && request.requestedSeason && 
                        ` S${request.requestedSeason}`}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    {request.status ? (
                      <select
                        value={request.status._id}
                        onChange={(e) => {
                          updateStatusMutation.mutate({
                            requestId: request._id,
                            statusId: e.target.value
                          });
                        }}
                        className="text-sm rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                        style={{ 
                          backgroundColor: request.status.color + '20', 
                          color: request.status.color,
                          borderColor: request.status.color
                        }}
                      >
                        {statuses.map((status) => (
                          <option 
                            key={status._id} 
                            value={status._id}
                            style={{ 
                              backgroundColor: 'white',
                              color: status.color 
                            }}
                          >
                            {status.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        value=""
                        onChange={(e) => {
                          updateStatusMutation.mutate({
                            requestId: request._id,
                            statusId: e.target.value
                          });
                        }}
                        className="text-sm rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      >
                        <option value="" disabled>Selectează status</option>
                        {statuses.map((status) => (
                          <option 
                            key={status._id} 
                            value={status._id}
                            style={{ 
                              backgroundColor: 'white',
                              color: status.color 
                            }}
                          >
                            {status.name}
                          </option>
                        ))}
                      </select>
                    )}
                    <button
                      onClick={() => setSelectedRequest(request)}
                      className="p-1 hover:bg-gray-100 rounded-full"
                      title={request.notes ? 'Vezi/Editează note' : 'Adaugă note'}
                    >
                      <svg 
                        className={`w-5 h-5 ${request.notes ? 'text-primary-600' : 'text-gray-400'}`}
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" 
                        />
                      </svg>
                    </button>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {request.requestedBy?.username || request.requestedByUsername || '[Utilizator șters]'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatTimeAgo(request.createdAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {request.updatedAt !== request.createdAt ? 
                    formatTimeAgo(request.updatedAt) : 
                    '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <button
                    onClick={() => handleDeleteRequest(request._id)}
                    className="text-red-600 hover:text-red-900 transition-colors duration-150"
                  >
                    Șterge
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <NotesModal
        isOpen={!!selectedRequest}
        onClose={() => setSelectedRequest(null)}
        notes={selectedRequest?.notes}
        onSave={(notes) => {
          updateNotesMutation.mutate({
            requestId: selectedRequest._id,
            notes
          });
        }}
        isAdmin={true}
      />
    </div>
  );
}
